



















import { computed } from '@vue/composition-api';

export default {
  components: {
    AButton: () => import('@/components/atoms/AButton.vue')
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    event: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, ctx) {
    const inputValue = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const eventVal = computed({
      get: () => props.event,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    function closeDialog() {
      ctx.emit('toggle');
    }

    return { inputValue, eventVal, closeDialog };
  }
};
